import "./css/bootstrap.min.css";
import "./css/App.css";

import { useEffect, useState, useRef } from "react";

function HandSelect({
  СancelScan,
  FindInBarcodeArray,
  LastScaned,
  InpFindNameRef,
  InpFindNameStr,
  Nomenklatura_options,
  setInpFindNameStr,
  setFoucus,
}) {
  useEffect(() => {
    setFoucus(InpFindNameRef);
  }, []);

  return (
    <div>
      <div>
        <button className="btn btn-primary" onClick={() => СancelScan()}>
          Отмена сканирования
        </button>
      </div>
      <div>
        <span>Выбор номенклатуры для шк: </span>
        <span>
          <b>{LastScaned.shk}</b>
        </span>
      </div>
      <div>
        <input
          style={{ "margin-right": "10px" }}
          ref={InpFindNameRef}
          type="text"
          value={InpFindNameStr}
          onChange={(event) => setInpFindNameStr(event.target.value)}
          onKeyUp={(event) => FindInBarcodeArray(event, true)}
        />
        <button
          className="btn btn-success"
          onClick={() => FindInBarcodeArray()}
        >
          Найти
        </button>
      </div>
      <div className="ButtonLists">{Nomenklatura_options}</div>
    </div>
  );
}
export default HandSelect;
