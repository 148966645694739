// import logo from "./logo.png";
import "./css/bootstrap.min.css";
import "./css/App.css";
import { BallTriangle } from "react-loader-spinner";
import HandSelect from "./HandSelect";
import useSound from "use-sound";
import errSound from "./sound/err.mp3";

import { useEffect, useState, useRef } from "react";

function App() {
  let [token, settoken] = useState("");
  let [login, setlogin] = useState("");
  let [tokenState, settokenState] = useState(false);
  let [wrong_token, setwrong_token] = useState(false);

  let [WIList, setWIList] = useState([]);
  let [ChoosWorkingInventoryVisible, setChoosWorkingInventoryVisible] =
    useState(false);
  let [WorkingInventory, setWorkingInventory] = useState({
    id: "",
    ArrayLastChangedOnServer: [],
  });
  let [NumPosition, setNumPosition] = useState(0);
  let [ClickQantityToSend, setClickQantityToSend] = useState(2);
  let [ListLastFrontPosition, setListLastFrontPosition] = useState([]);
  let [LastScaned, setLastScaned] = useState({});
  let [ModifiedEliment, setModifiedEliment] = useState({});
  let [BarcodeArray, setBarcodeArray] = useState([
    { name: "Книга3", shk: "789" },
    { name: "Книга1", shk: "123" },
    { name: "Книга2", shk: "456" },
  ]);
  let [InpFindNameStr, setInpFindNameStr] = useState("");
  let InpFindNameRef = useRef(null);
  let [FindInBarcodes, setFindInBarcodes] = useState([]);
  let [InpShk, setInpShk] = useState("");
  let [HandSelectVisible, setHandSelectVisible] = useState(false);
  let [ShowLoader, setShowLoader] = useState(true);
  let [DeleteFromServerDialog, setDeleteFromServerDialog] = useState(false);
  let inputShkRef = useRef(null);
  const [playErr] = useSound(errSound);
  useEffect(() => {
    if (!sessionStorage.getItem("sesion_id")) {
      console.log("USEeFECT NO SESSION");
      setShowLoader(false);
    } else {
      fetch("/token", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        // body: JSON.stringify({ token: token }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log("result: ", result);
            if (result.token === "not found") {
              setwrong_token(true);
              setShowLoader(false);
            } else if (result.token === "bad session") {
              setShowLoader(false);
            } else {
              console.log("token ok!");
              setBarcodeArray(result.shk_obj);
              setWIList(result.WIList);
              setShowLoader(false);
              if (result.WIList.length > 0) {
                setChoosWorkingInventoryVisible(true);
              }
              settokenState(true);
              setShowLoader(false);
            }
          },
          (error) => {
            console.log("token wrong!!!");
            // this.setState({
            //   isLoaded: true,
            //   error,
            // });
            alert(error);
          }
        );
    }
    // fetch("/getData", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json;charset=utf-8" },
    //   // body: JSON.stringify({ QantityShowStr: 2 }),
    // })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       setBarcodeArray(result.shk_obj);
    //       setWIList(result.WIList);
    //       setShowLoader(false);
    //       if (result.WIList.length > 0) {
    //         setChoosWorkingInventoryVisible(true);
    //       }
    //     },
    //     (error) => {
    //       // this.setState({
    //       //   isLoaded: true,
    //       //   error,
    //       // });
    //       alert(error);
    //     }
    //   );
  }, []);

  function sandKey() {
    setShowLoader(true);
    fetch("/token", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify({ token, login }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result: ", result);
          if (result.token === "not found") {
            setwrong_token(true);
            setShowLoader(false);
          } else {
            console.log("token ok!");
            settokenState(true);
            sessionStorage.setItem("sesion_id", result.sesion_id);
            setBarcodeArray(result.shk_obj);
            setWIList(result.WIList);
            setShowLoader(false);
            if (result.WIList.length > 0) {
              setChoosWorkingInventoryVisible(true);
            }
          }
        },
        (error) => {
          console.log("token wrong!!!");
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
          alert(error);
        }
      );
  }

  function NewInventoryInit() {
    let date = new Date();
    let FullDate = `${date.getFullYear()}.${
      date.getMonth() + 1
    }.${date.getDate()}_${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}`;
    let NameOfObj = prompt(
      "Введите наименование объекта инвентаризации",
      "Без указания обьекта инвентаризации"
    );
    let tmp_inventID = NameOfObj + "_" + FullDate;

    fetch("/newInventory", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify({ id: tmp_inventID }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let WI = { id: result.id, ArrayLastChangedOnServer: [] };
          setWorkingInventory(WI);
          setListLastFrontPosition([]);
          setNumPosition(0);
          setLastScaned({});
        },
        (error) => {
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
          alert(error);
        }
      );
    setFoucus(inputShkRef);
  }

  function setFoucus(ref) {
    ref.current.focus();
  }

  function getInventory(id) {
    fetch("/getInventory", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      // body: JSON.stringify(send_mass),
      body: JSON.stringify({ id, QantityShowStr: 10 }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.hasOwnProperty("err")) {
            alert("Ошибка чтения инвентаризации на сервере: " + result.err);
            alert("Попробуйте перезагрузить страницу");
          } else {
            setWorkingInventory(result);
            setChoosWorkingInventoryVisible(false);
          }
        },
        (error) => {
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
          alert(error);
        }
      );
  }

  function SendOnServer(InventPaket) {
    setNumPosition(0);
    if (WorkingInventory.id !== "") {
      fetch("/saveLastOnFront", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({ id: WorkingInventory.id, InventPaket }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setListLastFrontPosition([]);
            setWorkingInventory(result);
          },
          (error) => {
            // this.setState({
            //   isLoaded: true,
            //   error,
            // });
            alert(error);
          }
        );
    } else {
      alert("Не задано имя инвентаризации нажмите + и задайте имя");
    }
  }

  function AddToInventary(event) {
    if (event.keyCode === 13) {
      if (InpShk !== "") {
        // Просматриваем масив штрихкодов на наличие ШК.
        let findShk_obj = BarcodeArray.find((item) => item.shk === InpShk);
        if (findShk_obj !== undefined) {
          //Штрихкод найден в базе, проверим наличие его в текущих отсканированных элементах
          setLastScaned(findShk_obj);
          let inInventList = false;
          let temp_invent_mass = ListLastFrontPosition.map((item, index) => {
            if (item.shk === InpShk) {
              //ШК Найден в последних отсканированных меняем количиство на
              inInventList = true;
              item.num += 1;
              // item.num += PositionQuantity;
              // item.colored = true;
              return item;
            } else {
              // item.colored = false;
              return item;
            }
          });
          if (!inInventList) {
            let temp_elem = { ...findShk_obj };
            temp_elem.num = 1;
            // temp_elem.num = PositionQuantity;
            // temp_elem.colored = true;
            temp_invent_mass.push(temp_elem);
          }

          setListLastFrontPosition(temp_invent_mass);
          setInpShk("");
          console.log("NumPosition: ", NumPosition);
          if (NumPosition + 1 >= ClickQantityToSend) {
            SendOnServer(temp_invent_mass);
            // setNumPosition(0);
          } else {
            setNumPosition(NumPosition + 1);
          }
        } else {
          // ШК нет в базе переходим к выбору номенклатуры вручную из списка
          playErr();
          setLastScaned({ shk: InpShk });
          setHandSelectVisible(true);
          setNumPosition(NumPosition + 1);
        }
      }
    }
  }

  function SelectName(item) {
    let tmp_BarcodeArray = [...BarcodeArray];
    let tmp_elem = {
      shk: InpShk,
      name: item.name,
      num: 1,
      // num: PositionQuantity,
      // colored: true,
      in1c: false,
    };
    setLastScaned(tmp_elem);
    tmp_BarcodeArray.push(tmp_elem);
    setBarcodeArray(tmp_BarcodeArray);

    let temp_ListLastFrontPosition = [...ListLastFrontPosition];

    temp_ListLastFrontPosition.push(tmp_elem);
    setListLastFrontPosition(temp_ListLastFrontPosition);
    setInpShk("");
    setHandSelectVisible(false);
    setInpFindNameStr("");
    setFindInBarcodes([]);

    if (NumPosition >= ClickQantityToSend) {
      SendOnServer(temp_ListLastFrontPosition);
    }
  }

  function InventoryOFF() {
    if (WorkingInventory.id !== "") {
      // let WIArray = [
      //   ...ListLastFrontPosition,
      //   ...WorkingInventory.ArrayLastChangedOnServer,
      // ];

      fetch("/inventoryOFF", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({
          id: WorkingInventory.id,
          InventPaket: ListLastFrontPosition,
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.hasOwnProperty("err")) {
              alert(result.err);
            } else {
              let newWIList = WIList.filter((item) => item !== result.id);
              setWIList(newWIList);
              setListLastFrontPosition([]);
              setWorkingInventory({ id: "", ArrayLastChangedOnServer: [] });
              setLastScaned({});
            }
          },
          (error) => {
            // this.setState({
            //   isLoaded: true,
            //   error,
            // });
            alert(error);
          }
        );
    } else {
      alert("Инвентаризация не задана!");
    }
  }

  function FindInBarcodeArray(event, GetEnter) {
    if (GetEnter) {
      if (event.keyCode === 13 && event.target.value !== "") {
        let findArray = BarcodeArray.filter((item) => {
          if (item.name.indexOf(InpFindNameStr) !== -1) {
            return item;
          } else {
            return null;
          }
        });

        if (findArray.length > 0) {
          setFindInBarcodes(findArray);
        } else {
          setFindInBarcodes([]);
          alert("Не найдено.");
        }
      }
    } else {
      let findArray = BarcodeArray.filter((item) => {
        if (item.name.indexOf(InpFindNameStr) !== -1) {
          return item;
        } else {
          return null;
        }
      });

      if (findArray.length > 0) {
        setFindInBarcodes(findArray);
      } else {
        setFindInBarcodes([]);
        alert("Не найдено.");
      }
    }
  }

  function ViweDeleteFromServerDialog(obj) {
    setModifiedEliment(obj);
    setDeleteFromServerDialog(true);
  }

  function CahngeFrontIventoryList(obj) {
    let temp_invent_list = ListLastFrontPosition.filter(
      (i) => i.shk !== obj.shk
    );
    let qantity = Number(prompt("Введите кол-во, 0 удалит эелемент списка", 0));
    if (qantity > 0) {
      obj.num = qantity;
      temp_invent_list.push(obj);
    }
    setListLastFrontPosition([]);
    SendOnServer(temp_invent_list);
    setFoucus(inputShkRef);
  }

  function DeleteElementFromServer() {
    fetch("/DeleteInventoryString", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify({
        id: WorkingInventory.id,
        shk: ModifiedEliment.shk,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.hasOwnProperty("err")) {
            alert(result.err);
          } else {
            setModifiedEliment({});
            setWorkingInventory(result);
            setLastScaned({});
          }
        },
        (error) => {
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
          alert(error);
        }
      );
    setDeleteFromServerDialog(false);
  }

  function СancelScan() {
    setInpShk("");
    setNumPosition(0);
    setLastScaned({});
    setInpFindNameStr("");
    setHandSelectVisible(false);
    setFindInBarcodes([]);
  }

  let ListLastFrontPositionTable = ListLastFrontPosition.map((item, index) => {
    return (
      <tr
        style={{ color: "#dfdd69" }}
        onClick={() => CahngeFrontIventoryList(item)}
        key={index}
      >
        <td className="TD_SHK">{item.shk}</td>
        <td className="TD_Name">{item.name}</td>
        <td className="TD_Num">{item.num}</td>
      </tr>
    );
  });

  let LastPositionsAddedOnServerTable =
    WorkingInventory.ArrayLastChangedOnServer.map((item, index) => {
      return (
        <tr
          onClick={() => ViweDeleteFromServerDialog(item)}
          style={{ color: "green" }}
          key={index}
        >
          <td className="TD_Shk">{item.shk}</td>
          <td className="TD_Name">{item.name}</td>
          <td className="TD_Num">{item.num}</td>
        </tr>
      );
    });

  let tmpname = "";
  let Nomenklatura_options = FindInBarcodes.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }).map((item, index) => {
    if (item.name !== tmpname) {
      tmpname = item.name;
      return (
        <button
          className="btn btn-warning ButtonLists"
          onClick={() => SelectName(item)}
          key={index}
        >
          {item.name}
        </button>
      );
    }
  });

  let ListWorksInventory = WIList.map((item, index) => {
    return (
      <button
        type="button"
        className="btn btn-warning InventoryLists"
        onClick={() => getInventory(item)}
      >
        {item}
      </button>
    );
  });

  let token_ask_form = (
    <div className="position-relative min-vw-100 min-vh-100 ">
      <div className="position-absolute top-50 start-50 translate-middle w-25">
        <div className="mb-3 text-center">
          <p className="fw-bold fs-4">
            {wrong_token ? "Пара логин пароль указаны не верно!" : ""}
          </p>
          <label htmlFor="exampleInputlogin" className="form-label">
            Логин
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(event) => setlogin(event.target.value)}
            value={login}
            id="exampleInputlogin"
          />
          <label htmlFor="exampleInputPassword1" className="form-label">
            Пароль
          </label>
          <input
            type="password"
            className="form-control"
            onChange={(event) => settoken(event.target.value)}
            value={token}
            id="exampleInputPassword1"
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary position-absolute end-0"
          onClick={() => sandKey()}
        >
          Войти
        </button>
      </div>
    </div>
  );

  return (
    <div className="App">
      <header className="App-header"></header>
      <body>
        <main>
          {ShowLoader ? (
            <div className="loader">
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0d6efd"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </div>
          ) : tokenState ? (
            ChoosWorkingInventoryVisible ? (
              <div className="ButtonLists">{ListWorksInventory}</div>
            ) : HandSelectVisible ? (
              <HandSelect
                СancelScan={СancelScan}
                FindInBarcodeArray={FindInBarcodeArray}
                LastScaned={LastScaned}
                InpFindNameRef={InpFindNameRef}
                InpFindNameStr={InpFindNameStr}
                Nomenklatura_options={Nomenklatura_options}
                setFoucus={setFoucus}
                setInpFindNameStr={setInpFindNameStr}
              />
            ) : DeleteFromServerDialog ? (
              <div>
                <h6>Вы подтверждаете полное удаление из инвентаризации?</h6>
                <div className="navbar" id="chElemMenu">
                  <button
                    onClick={() => DeleteElementFromServer()}
                    className="btn btn-danger"
                  >
                    УДАЛИТЬ
                  </button>
                  <button
                    onClick={() => setDeleteFromServerDialog(false)}
                    className="btn btn-success"
                  >
                    НET
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {WorkingInventory.id ? (
                  <h6>{WorkingInventory.id}</h6>
                ) : (
                  <h3 id="TaskLog">Инвентаризация</h3>
                )}

                <dev className="navbar">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => NewInventoryInit()}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => InventoryOFF()}
                  >
                    =&gt;
                  </button>
                  <dev>
                    {/* <span>
                    <b> Кол-во пиков до отправки на сервер: </b>
                  </span> */}
                    <input
                      id="InpClickQantityToSend"
                      type="text"
                      value={ClickQantityToSend}
                      onChange={(event) => {
                        setClickQantityToSend(event.target.value);
                      }}
                    />
                  </dev>
                  <dev>
                    <input
                      id="InpShkid"
                      autoFocus
                      ref={inputShkRef}
                      type="text"
                      value={InpShk}
                      onChange={(event) => setInpShk(event.target.value)}
                      onKeyUp={(event) => AddToInventary(event)}
                    />
                  </dev>
                </dev>
                <div id="findbar">
                  <div style={{ color: "red" }}>
                    {LastScaned ? LastScaned.shk : ""}
                  </div>
                  <div style={{ color: "red" }}>
                    {LastScaned ? LastScaned.name : ""}
                  </div>
                </div>
                <table id="InventaryTable" className="table">
                  <thead>
                    <tr>
                      <th>ШК</th>
                      <th>Номенклатура</th>
                      <th>ШТ</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {ListLastFrontPositionTable}
                    {LastPositionsAddedOnServerTable}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td>строк:</td>
                      <td>
                        {WorkingInventory.ArrayLastChangedOnServer.length +
                          ListLastFrontPosition.length}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )
          ) : (
            token_ask_form
          )}
        </main>
      </body>
    </div>
  );
}

export default App;
